import React, { useState } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import SectionContainerLayoutWithFilter from '../../components/section-container-layout-with-filter';
import CardImageTitleDescription from '../../components/card-image-title-description/card-image-title-description';
import moment from 'moment';
import { SignalCellularNullOutlined } from '@material-ui/icons';

const GalleryIndexPage = ({ pageContext: { _albumsArray, _albumYearsArray } }) => {
  const [years, setYears] = useState(_albumYearsArray.sort((a, b) => b - a));
  const [hasResults] = useState(true);

  return (
    <Layout
      children={
        <>
          <SEO lang='en' title='Gallery | Global Manufacturing & Industrialisation Summit' />
          <SectionContainerLayoutWithFilter title='GALLERY' baseLink='/galleries' filters={years} isViewAll isDivider>
            <Grid container spacing={3}>
              {hasResults ? (
                years.map((_year, yearIndex) => (
                  <>
                    <Grid item xs={12}>
                      <Typography variant='h6' color='textPrimary'>
                        {_year}
                      </Typography>
                    </Grid>
                    {_albumsArray.map((_album, index) =>
                      _album.year === _year ? (
                        <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={3}>
                          {_album.files.some((_file) => _file.type !== 'video/mp4') ? (
                            <CardImageTitleDescription
                              isImgTag
                              image={_album.files.find((_file) => _file.type !== 'video/mp4').code}
                              title={moment(_album.date).format('DD MMM YYYY')}
                              body={_album.title}
                              ctaText={_album.albumType === 'videos' ? 'View Video Library' : 'View Album'}
                              readMore={`/galleries/${_album.year}/${_album.urlSlug}`}
                            />
                          ) : null}
                        </Grid>
                      ) : null
                    )}
                  </>
                ))
              ) : (
                <Grid item xs={12}>
                  <Box m={8} p={{ xs: 0, sm: 8 }} fullWidth textAlign='center'>
                    <Typography color='textSecondary' variant='button'>
                      No videos Found
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          </SectionContainerLayoutWithFilter>
        </>
      }
    />
  );
};

export default GalleryIndexPage;
